.MuiFormControl-root {
  padding: 0;
  margin: 0;
}

.MuiInputBase-root {
  padding: 0;
  margin: 0;
  background-color: white;
}

.MuiAutocomplete-input {
  padding: 0;
  margin: 0;
}
