:root {
  --event-max-width: 230px;
}

.big-calendar-container {
  overflow: hidden;
  color: #344767;

  position: relative;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    position: relative;

    padding: 12px 12px;

    .controllers {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;

      .controller {
        display: flex;
        flex-direction: row;
        gap: 2px;
        align-items: center;
        height: 31px;
        border-radius: 6px;
        overflow: hidden;

        .controller-button {
          border: none;
          background-color: whitesmoke;
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 1;
          height: 100%;

          &:hover {
            background-color: rgb(226, 226, 226);
            cursor: pointer;
          }
        }

        .controller-description {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 12px;
          height: 100%;
          background-color: whitesmoke;
          font-size: 16px;
          margin: 0;
          width: 90px;
        }
      }
    }

    .layout-switcher {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      border-radius: 6px;
      overflow: hidden;
      gap: 2px;

      .layout-element {
        background-color: whitesmoke;
        border: none;
        padding: 6px 12px;
        font-size: 16px;
        font-weight: 500;
        width: 100px;

        font-family: "Roboto";

        &.selected {
          background-color: #dc1b47;
          color: white;
          pointer-events: none;
        }

        &:hover {
          background-color: rgb(232, 232, 232);
          cursor: pointer;
        }
      }
    }
  }

  .big-calendar-table {
    width: 100%;

    border-collapse: collapse;

    .big-calendar-table-tr {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;

      th {
        flex: 1;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: end;
        padding: 0 8px;
      }

      td {
        padding: 0;
        margin: 0;

        border: 2px solid rgb(235, 235, 235); /*rgb(235, 235, 235)*/
        border-left: none;
        border-bottom: none;

        box-sizing: border-box;

        flex: 1;
        background-color: white;
        height: 150px;

        display: flex;
        flex-direction: column;
        align-items: end;

        &:hover {
          box-shadow: inset 0 0 0 1px rgb(211, 211, 211);
          z-index: 100;
        }

        &.none {
          background-color: whitesmoke;
        }

        &.today {
          box-shadow: inset 0 0 0 2px #dc1b47;
          z-index: 200;
        }

        &.dragging-over {
          box-shadow: inset 0 0 0 2px #48c2c3;
          z-index: 300;
        }

        .date {
          padding: 0 8px;
          margin: 0;
          white-space: nowrap;
        }

        .events {
          flex: 1;
          display: flex;
          flex-direction: column;

          padding: 6px;
          gap: 4px;

          overflow: auto;

          width: 100%;
          max-width: var(--event-max-width);

          .show-more {
            background-color: transparent;
            border: none;
            padding: 0;
            text-align: start;
            font-family: "Roboto";

            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            color: rgb(171, 171, 171);

            &:hover {
              text-decoration: underline;
            }
          }

          .month-event {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 4px;
            overflow: hidden;
            width: 100%;

            .event {
              font-size: 14px;
              font-weight: 600;
              background-color: #48c2c3;
              border-radius: 4px;
              color: white;
              padding: 0px 6px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              &:hover {
                cursor: move;
              }

              &.generating-pdf {
                text-overflow: initial;
                white-space: initial;
                overflow: initial;
              }
            }

            .time {
              font-size: 14px;
              font-weight: 500;
              color: rgb(171, 171, 171);
              white-space: nowrap;
              text-align: end;
            }
          }

          .event {
            font-size: 14px;
            font-weight: 600;
            background-color: #48c2c3;
            border-radius: 4px;
            color: white;
            padding: 0 6px;

            white-space: nowrap;
            &:hover {
              cursor: move;
            }
          }

          &.time {
            width: initial;
            max-width: initial;
          }
        }

        &.generating-pdf {
          height: auto;
          min-height: max(100%, 0px);
        }
      }

      &.time {
        td {
          align-items: center;
          height: initial;
          padding: 0 8px;

          display: flex;
          flex-direction: row;
          gap: 20px;

          .time-value {
            font-size: 15px;
            font-weight: 500;
            text-align: end;
            width: 80px;
          }

          .events {
            flex: 1;
            display: flex;
            flex-direction: row;

            width: 100%;
            padding: 8px;
            gap: 6px;
            flex-wrap: wrap;

            .event {
              font-size: 14px;
              font-weight: 600;
              background-color: #48c2c3;
              border-radius: 4px;
              color: white;
              padding: 0 6px;
            }
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(245, 245, 245, 0.738);
    backdrop-filter: blur(2px);

    z-index: 500;
  }
}
