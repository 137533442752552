:root {
  --var-blue-0: #ecf3ff;
  --var-blue-1: #b3ceff;
  --var-color-1: #7aa9ff;
  --var-blue-3: #5490ff;
  --var-color-2: #2f78ff;
  --var-blue-5: #124076;
  --var-color-3: #ffffb6;

  --var-font-1: red;
  --var-font-2: red;
  --var-font-3: red;

  --var-pdf-width: 21cm;
  --var-pdf-height: 29.7cm;

  --var-page-margin: 20px; /*1.27cm */

  --var-cover-logo: 9.6266cm;

  --var-cover-info-gap: 2.54cm;

  --var-cover-info-1-width: 12.2428cm;
  --var-cover-info-1-height: 4.5466cm;
  --var-cover-info-2-width: 10.2362cm;
  --var-cover-info-2-height: 3.4798cm;

  --var-cover-flag-height: 2.057cm;
  --var-cover-flag-width: 2.2606cm;

  --var-cover-confidentail-width: 5.2832cm;
  --var-cover-confidentail-height: 4.318cm;

  --var-cover-ifpc-logo-width: 3.4544cm;
  --var-cover-ifpc-logo-height: 2.0574cm;

  --var-font-10: 13px;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  margin-bottom: 50px;
}
.report-cover {
  height: var(--var-pdf-height);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 20px;
}
.flag {
  /*font-size: var(--var-cover-flag-size) !important;*/
  width: var(--var-cover-flag-width);
  height: var(--var-cover-flag-height);
}

.report-cover-logo {
  width: var(--var-cover-logo) !important;
  height: var(--var-cover-logo) !important;
  cursor: pointer;
  position: relative;
}
.report-cover-logo img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cover-info-container-box {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  margin-top: 150px;
  gap: var(--var-cover-info-gap);
}

.cover-info-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
}

.cover-info-rating > span:first-of-type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.cover-info-rating > span:first-of-type > span:first-of-type {
  color: var(--var-color-1);
}

.cover-info-rating > span:last-of-type {
  color: black;
  font-size: 3.5rem;
}

.cover-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cover-info-container > div:first-of-type {
  background-color: var(--var-color-1);
  color: var(--var-font-1);
  border-radius: 0 100px 100px 0;
  padding: 5px 150px 5px 5px;
  width: var(--var-cover-info-1-width);
  height: var(--var-cover-info-1-height);
  display: flex;
  align-items: center;
}

.cover-info-container > div:first-of-type > span {
  font-size: 7rem;
  position: relative;
  font-weight: bolder;
}

.cover-info-container > div:first-of-type > span > span {
  font-size: 7rem;
  font-weight: 300;
}

.cover-info-container > div:first-of-type > span.assessment::before {
  content: "Consolidated";
  color: var(--var-font-1);
  font-size: 2rem;
  position: absolute;
  top: 25%;
  left: 100%;
  font-weight: bold;
}

.cover-info-container > div:first-of-type > span::after {
  content: "Report";
  color: var(--var-font-1);
  font-size: 2rem;
  position: absolute;
  top: 45%;
  left: 100%;
  font-weight: bold;
}

.cover-info-container > div:last-of-type {
  background-color: var(--var-color-2);
  color: var(--var-font-2);
  border-radius: 0 70px 70px 0;
  padding: 2px 20px 2px 40px;
  transform: translateY(-10px);
  width: var(--var-cover-info-2-width);
  height: var(--var-cover-info-2-height);
  display: flex;
  flex-direction: column;
}

.cover-info-container > div:last-of-type > span:first-of-type {
  font-size: 1rem;
  font-weight: bold;
  display: block;
  color: var(--var-font-1);
}

.cover-info-container > div:last-of-type > span:last-of-type {
  font-size: 1rem;
  font-weight: bold;
  display: block;
}

.cover-confidental {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-left: 4rem;
  padding-right: 2rem;
  padding-bottom: 50px;
}
.cover-confidental img:first-of-type {
  width: var(--var-cover-ifpc-logo-width);
  height: var(--var-cover-ifpc-logo-height);
  object-fit: contain;
  transform: translateY(30px);
}
.cover-confidental img:last-of-type {
  width: var(--var-cover-confidentail-width);
  height: var(--var-cover-confidentail-height);
  object-fit: contain;
}

.cover-footer {
  text-align: center;
  font-size: var(--var-font-10);
  font-weight: 700;
}

.draggable-item {
  cursor: move;
}

.dragging {
  opacity: 0.5;
  border: 2px dashed gray;
}

.pdf-item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  width: var(--var-pdf-width);
}
.pdf-margin {
  width: var(--var-page-margin);
  background-color: transparent;
  height: 100%;
}

.pdf-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  width: "100%";
  height: "100%";
}

.pdf-item table {
  width: 100%;
  table-layout: auto;
}

.pdf-item .title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: var(--var-color-1);
  height: 100px;
  color: var(--var-font-1);
  font-size: 29.26px;
  font-weight: 700;
  margin: 20px 0;
}

.pdf-item .abbreviation-list {
  width: 100%;
  background-color: var(--var-color-2);
  color: var(--var-font-2);
  font-size: 22px;
  font-weight: 700;
  padding: 10px;
  margin: 250px 0;
}

.pdf-item .abbreviation-list ol {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.pdf-item .table {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pdf-item .table table {
  width: 100%;
  border-collapse: collapse;
}

.pdf-item .table thead {
  background-color: #f2f2f2;
}

.pdf-item .table th {
  border: 1px solid black;
  padding: 0px !important;
  text-align: left;
}

.pdf-item .table tbody tr td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.pdf-item .subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 32px;
  font-weight: 700;
}

.pdf-item .subtitle .line {
  height: 2px;
  flex: 1;
  background-color: var(--var-color-1);
}

.pdf-item .risk-analysis-table {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pdf-item .risk-analysis-table > div {
  background-color: var(--var-color-2);
  color: var(--var-font-2);
  padding: 5px 15px;
  width: 70%;
  height: 200px;
}

.pdf-item .risk-analysis-table table {
  border-collapse: collapse;
  border: none;
  width: 100%;
  height: 100%;
}

.pdf-item .risk-analysis-table table thead th {
  border: none;
  padding: 5px 50px;
  text-align: center;
  border-bottom: 2px solid var(--var-font-2);
  font-size: 1.5rem;
}

.pdf-item .risk-analysis-table table thead th:nth-child(even) {
  border-left: 2px solid var(--var-font-2);
}

.pdf-item .risk-analysis-table table tbody tr td {
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
}

.pdf-item .risk-analysis-table table tbody tr td:nth-child(even) {
  border-left: 2px solid var(--var-font-2);
}

.pdf-item .perf-ratings {
  display: flex;
  gap: 20px;
}

.pdf-item .perf-ratings .perfs {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pdf-item .perf-ratings .perfs .perf {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 20px;
  font-size: 32px;
  font-weight: 700;
}

.pdf-item .perf-ratings .perfs .perf .arrow {
  display: flex;
  align-items: center;
  flex: 1;
}

body .pdf-item .perf-ratings .perf-levels .arrow.vertical {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body .pdf-item .perf-ratings .perf-levels .arrow.vertical .arrow-tail {
  flex: 1;
  width: 2px;
  background-color: black;
}

body .pdf-item .perf-ratings .perf-levels .arrow.vertical .arrow-head {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent black;
  transform: rotate(90deg);
}

.pdf-item .perf-ratings .perfs .perf .arrow .arrow-tail {
  height: 2px;
  flex: 1;
  background-color: black;
}

.pdf-item .perf-ratings .perfs .perf .arrow .arrow-head {
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent black;
}

.pdf-item .flag {
  font-size: 32px;
}

.pdf-item .flag.red {
  color: red;
}

.pdf-item .flag.orange {
  color: orange;
}

.pdf-item .flag.yellow {
  color: yellow;
}

.pdf-item .flag.yellowgreen {
  color: yellowgreen;
}

.pdf-item .flag.green {
  color: green;
}

.pdf-item .perf-ratings .perf-levels {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 32px;
  font-weight: 700;
}

.pdf-item table,
.pdf-item th,
.pdf-item td {
  border: 1px solid black;
  border-collapse: collapse;
}

.pdf-item table thead th {
  padding: 0px !important;
  text-align: center;
}

.pdf-item table thead th,
.pdf-item table thead td {
  font-family: Helvetica, Arial, sans-serif;
}

.pdf-item table td {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
}

.pdf-item table .calibri-cell {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
}

.pdf-item table .helvetica-cell {
  font-family: Helvetica, Arial, sans-serif;
}

.pdf-item table .color-2-cell {
  background-color: var(--var-color-2);
  color: var(--var-font-2);
  text-align: center;
}

.pdf-item table .white-cell {
  background-color: white;
  text-align: center;
}

.pdf-item table .white-cell textarea {
  font-family: Calibri, "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  font-size: 16px;
}

.pdf-item table .bold-12-cell {
  font-weight: 700;
  font-size: 16px;
}

.pdf-item table .bold-10-cell {
  font-weight: 700;
  font-size: 13px;
}

.pdf-item table .width-4-cell {
  width: 4%;
}
.pdf-item table .width-5-cell {
  width: 5%;
}
.pdf-item table .width-7-cell {
  width: 7%;
}
.pdf-item table .width-10-cell {
  width: 10%;
}
.pdf-item table .width-15-cell {
  width: 15%;
}
.pdf-item table .width-25-cell {
  width: 25%;
}
.pdf-item table .width-44-cell {
  width: 44%;
}
.pdf-item table .width-45-cell {
  width: 45%;
}
.pdf-item table .width-50-cell {
  width: 50%;
}
.pdf-item table .width-55-cell {
  width: 55%;
}
.pdf-item table .width-65-cell {
  width: 65%;
}
.pdf-item table .width-30-cell {
  width: 30%;
}

.pdf-item table .color-3-cell {
  background-color: var(--var-color-3);
  color: var(--var-font-3);
  text-align: center;
}
.pdf-item table .left-align-cell {
  text-align: left !important;
}

.pdf-item table .blue-2-cell {
  background-color: var(--var-color-1);
  text-align: center;
  color: white;
}

.pdf-item table .blue-3-cell {
  background-color: var(--var-blue-3);
  text-align: center;
  color: white;
}

.pdf-item table .color-1-cell {
  background-color: var(--var-color-1);
  text-align: center;
  color: var(--var-font-1);
}

.pdf-item table .blue-5-cell {
  background-color: var(--var-blue-5);
  text-align: center;
  color: white;
}

.photo-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 10px 0;
  gap: 50px;
}
.photo-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.photo-info span:last-of-type {
  color: red;
}
.pie-charts {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  background-color: white;
  margin: 0;
  width: var(--var-pdf-width);
}
.pie-charts .pdf-item-container {
  all: unset;
  width: var(--var-pdf-width);
}
.pie-charts .pdf-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  width: var(--var-pdf-width);
}

.pie-chart {
  width: 200px;
  max-width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.pie-chart .pie-chart-circle {
  background: conic-gradient(
    from 0deg,
    red 0%,
    red 50%,
    #98958e 50%,
    #98958e 100%
  );
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 500px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pie-chart .pie-chart-circle .pie-center-value {
  background: white;
  width: 70%;
  height: 70%;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.pie-chart .pie-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 12px;
}

.pie-chart .pie-footer {
  font-size: 18px;
  font-weight: 600;
}
